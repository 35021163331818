import axios from "axios";
import CookieHelper from "./cookieHelper";
import store from "../store"
import { logOutUser } from "../reducers/login"


async function callApi(
    method = "POST",
    url,
    body = null,
    headers = { "Content-type": "application/json" },
    cancelToken
) {
    const cookieHelper = new CookieHelper();
    const userData = cookieHelper.getCookie();

    headers = {
        ...headers,
        "ngrok-skip-browser-warning": "69420",
    };

    if (userData) {
        headers.Authorization = userData?.token;
    }
    console.log("api call dat url>>>>>>>", url);
    // console.log("api call dat rcv>>>>>>>", body);
    // console.log("api call headers>>>>>>>", headers);

    return new Promise((resolve, reject) => {
        axios({
            method,
            url,
            data: body,
            headers,
            cancelToken: cancelToken,
        })
            .then((res) => {
                // console.log("network res>>", res)
                resolve(res);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    // logout use
                    // console.log("token expired logout user runn")
                    store.dispatch(logOutUser())
                }
                reject(err);
            });
    });
}

export default callApi;
