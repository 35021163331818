import React, { useState } from "react";
import { Form, Input, InputNumber } from "antd";
import Endpoints from "../config/apiConstants";
import callApi from "../helpers/networkHelper";
import { message } from "antd";
import { GrPrevious } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import PopUpLoader from "../components/PopUpLoader";
import Protean from "../js/protean";

function PartyTwoDataFillForm({
  formData,
  setSuccess,
  fetchPreviewPdf,
  setLeegalityData,
  setRedirected,
}) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [activePageName, setActivePageName] = useState("personalInfo");
  const countryOption = [{ value: "india", label: "India" }];

  function onFinish(e) {
    // console.log("submit data>>>", e)
    if (activePageName === "submit") {
      const formData = form.getFieldsValue();
      sendPartyTwoData(formData);
    }
  }

  //save party two data
  //generate esign link for party two
  //redirect party two for esign
  //if esign success save party two sign pdf in db
  async function sendPartyTwoData(data) {
    setLoading(true);
    const method = Endpoints.PARTY_TWO_DATA_UPDATE.method;
    const url = Endpoints.PARTY_TWO_DATA_UPDATE.url;
    const body = {
      userId: formData.userId,
      userName: formData.userName,
      formId: formData.formId,
      ...data,
    };
    try {
      const response = await callApi(method, url, body);
      console.log("update profile res>>", response);

      if (response.data.status) {
        message.success("Data updated successfully !!");
        //open redirect link in new window by creating protean instance
        const url = response.data.esignRedirectUrl;
        const obj = {
          logoUrl: "../assets/logo/logo-icon.svg",
          callback: (res) => sendSuccessSignRes(res),
        };
        const protean = new Protean(obj);
        protean.init();
        protean.esign(url);
        navigate("/");
      } else {
        message.error("Data updating fail !!");
      }
    } catch (err) {
      console.error("update party two data err", err);
    } finally {
      setLoading(false);
      setSuccess(true);
    }
  }

  async function onNextClick() {
    // personalInfo
    //address
    //submit
    if (activePageName === "personalInfo") {
      try {
        await form.validateFields(["party2_name", "party2_country"]);
      } catch (error) {
        // console.log("validation err>>", error)
        return message.error("Please provide all the correct information.");
      }
      setActivePageName("personalAddress");
    }
    if (activePageName === "personalAddress") {
      try {
        await form.validateFields([
          "party2_addressline1",
          "party2_addressline2",
          "party2_city",
          "party2_zip",
          "party2_state",
        ]);
      } catch (error) {
        // console.log("validation err>>", error)
        return message.error("Please provide all the correct information.");
      }
      setActivePageName("submit");
    }
  }
  function onPreClick() {
    if (activePageName === "submit") {
      setActivePageName("personalAddress");
    }
    if (activePageName === "personalAddress") {
      setActivePageName("personalInfo");
    }
  }

  function calculateTitle(pageName) {
    if (pageName === "personalInfo") {
      return "Personal Info";
    }
    if (pageName === "personalAddress") {
      return "Address";
    } else {
      return "";
    }
  }

  async function sendSuccessSignRes() {
    const method = Endpoints.SUCCESS_ESIGN_PARTY_TWO.method;
    const url = Endpoints.SUCCESS_ESIGN_PARTY_TWO.url;
    const body = {
      formId: formData.formId,
      recipentNumber: "2",
    };
    try {
      const response = await callApi(method, url, body);
      console.log("success signEs res>>", response);
      if (!response.data.status) {
        console.log("success sign update to db fail");
      }
    } catch (error) {
      console.log("success sign update error>>", error);
    }
  }

  return (
    <div>
      {/* <button
        onClick={() => {
          setShowIframe({
            show: true,
            link: "https://risewithprotean.io/products",
          });
        }}
      >
        temp
      </button> */}
      <h1 className="lg:hidden mx-6 my-2 font-bold">
        {calculateTitle(activePageName)}
      </h1>
      <div className="w-full mx-6 hidden lg:block">
        <ol className="flex justify-start items-center flex-wrap gap-2 py-2 lg:py-4 px-2 lg:px-8 lg:mx-30 text-gray-500">
          <li
            className={`flex items-center ${
              activePageName === "personalInfo"
                ? "text-blue-600 dark:text-blue-500"
                : ""
            } `}
          >
            Personal Info
            <svg
              className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 12 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m7 9 4-4-4-4M1 9l4-4-4-4"
              />
            </svg>
          </li>
          <li
            className={`flex items-center ${
              activePageName === "personalAddress"
                ? "text-blue-600 dark:text-blue-500"
                : ""
            } `}
          >
            Address
            <svg
              className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 12 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m7 9 4-4-4-4M1 9l4-4-4-4"
              />
            </svg>
          </li>
        </ol>
      </div>

      <Form
        name="partyDetailsForm"
        form={form}
        // onFinish={onFinish}
      >
        {/* personalInfo page */}
        <div
          className={`mx-6 mb-18 lg:mb-0  ${
            activePageName === "personalInfo" ? "block" : "hidden"
          }`}
        >
          <div>
            <span className="block mb-2 text-sm font-medium text-slate-400 mx-1">
              Full Name
            </span>
            <Form.Item
              name="party2_name"
              rules={[
                {
                  required: true,
                  message: "Please input your full name!",
                },
              ]}
            >
              <Input
                className="w-full text-xl py-4 rounded-lg"
                placeholder="Full Name"
              />
            </Form.Item>
          </div>
          <div>
            <span className="block mb-2 text-sm font-medium text-slate-400 mx-1">
              Country
            </span>
            <Form.Item
              name="party2_country"
              rules={[
                {
                  required: true,
                  message: "Please select your country!",
                },
              ]}
            >
              <select className="w-full  text-xl py-4 px-2 rounded-lg bg-white">
                <option value={""}>Selcet Country</option>
                {countryOption.map((x) => (
                  <option key={x.value} value={x.value}>
                    {x.label}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>
        </div>

        {/* personalAddress page */}
        <div
          className={`mx-6 mb-18 lg:mb-0  ${
            activePageName === "personalAddress" ? "block" : "hidden"
          }`}
        >
          <div>
            <span className="block mb-2 text-sm font-medium text-slate-400 mx-1">
              Address line 1
            </span>
            <Form.Item
              name="party2_addressline1"
              rules={[
                {
                  required: true,
                  message: "Please input your address!",
                },
              ]}
            >
              <Input
                className=" text-xl py-4 rounded-lg"
                placeholder="Address line 1"
              />
            </Form.Item>
          </div>
          <div>
            <span className="block mb-2 text-sm font-medium text-slate-400 mx-1">
              Address line 2
            </span>
            <Form.Item name="party2_addressline2">
              <Input
                className=" text-xl py-4 rounded-lg"
                placeholder="Address line 2"
              />
            </Form.Item>
          </div>
          <div>
            <span className="block mb-2 text-sm font-medium text-slate-400 mx-1">
              City
            </span>
            <Form.Item
              name="party2_city"
              rules={[
                {
                  required: true,
                  message: "Please input your city name!",
                },
              ]}
            >
              <Input className=" text-xl py-4 rounded-lg" placeholder="City" />
            </Form.Item>
          </div>
          <div>
            <span className="block mb-2 text-sm font-medium text-slate-400 mx-1">
              Pin
            </span>
            <Form.Item
              name="party2_zip"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value === "") {
                      return Promise.reject(
                        new Error("Please input your area pin code!")
                      );
                    }
                    const numberValue = Number(value);
                    if (/^\d{6}$/.test(numberValue)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The input is not valid area pin code!")
                    );
                  },
                }),
              ]}
            >
              <InputNumber
                className="w-full text-xl py-2 rounded-lg"
                placeholder="Pin"
              />
            </Form.Item>
          </div>
          <div>
            <span className="block mb-2 text-sm font-medium text-slate-400 mx-1">
              State
            </span>
            <Form.Item
              name="party2_state"
              rules={[
                {
                  required: true,
                  message: "Please input your state!",
                },
              ]}
            >
              <Input className=" text-xl py-4 rounded-lg" placeholder="State" />
            </Form.Item>
          </div>
        </div>

        {/* {activePageName === "submit" &&
                    <div className='h-[60vh] lg:h-auto  flex justify-center items-center text-white my-4 mx-6'>
                        <button className='w-full bg-blue-700 py-4 shadow-2 rounded-lg disabled:bg-slate-400' disabled={loading} htmltype="submit">
                            <div className='flex justify-center items-center px-10 text-2xl'>
                                <span>{loading ? "Loading..." : "Submit"}</span>
                            </div>
                        </button>
                    </div>} */}
        {activePageName === "submit" && (
          <div className="px-6">
            <p className="text-lg text-center">
              Please review your information carefully before submitting the
              form
            </p>
          </div>
        )}

        <div className="w-full fixed bottom-0 right-0 lg:bottom-6">
          <div className="flex justify-start items-center lg:justify-end">
            <div className="w-full lg:w-auto grid grid-cols-5 gap-2 mx-6 mb-4">
              <button
                disabled={
                  activePageName === "personalInfo" ||
                  activePageName === "companyInfo" ||
                  loading
                }
                className="col-span-1 w-[50px] bg-blue-700 text-white font-bold  py-2 px-4 shadow-4 rounded-lg disabled:bg-slate-400"
                onClick={onPreClick}
              >
                <GrPrevious />
              </button>
              {activePageName === "submit" ? (
                <button
                  onClick={onFinish}
                  className="col-span-4 bg-blue-700 text-white font-bold py-2 px-4 shadow-4 rounded-lg disabled:bg-slate-400"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              ) : (
                <button
                  disabled={activePageName === "submit"}
                  className="col-span-4 bg-blue-700 text-white font-bold py-2 px-4 shadow-4 rounded-lg disabled:bg-slate-400"
                  onClick={onNextClick}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      </Form>

      {/* <RedirectPage
        view={showIframe.show}
        setView={setShowIframe}
        link={showIframe.link}
      /> */}
      <PopUpLoader show={loading} />
    </div>
  );
}

export default PartyTwoDataFillForm;
