import React from "react";
import Sppiner from "./Sppiner";

function PopUpLoader({ show, setShow }) {
  return (
    <div
      className={`${
        show ? "fixed" : "hidden"
      } w-full h-full top-0 left-0 bg-white opacity-75 z-50`}
    >
      <div className="flex justify-center items-center mt-[50vh]">
        <Sppiner size={"70px"} />
      </div>
    </div>
  );
}

export default PopUpLoader;
