
// LeegalityException constructor function
function LeegalityException(message) {
  this.name = "LeegalityException";
  this.message = message;
}

// Leegality class definition
function Leegality(options) {
  if (!options || !options.callback) {
    throw new LeegalityException("Callback function is required.");
  }

  this.callback = options.callback;
  this.logoUrl = options.logoUrl;
  this.popup = null;
  this.responseData = null;
}

// Adding prototype methods
Leegality.prototype = {
  handleResponse: function (response) {
    this.cancel();
    this.callback(response);
    this.responseData = null;
  },

  getLoadingHtml: function () {
    const loadingHtml = `
      <html>
        <head>
          <meta http-equiv="Content-Security-Policy" content="default-src *; img-src * 'self' data: https:; script-src 'self' 'unsafe-inline' 'unsafe-eval' *; style-src 'self' 'unsafe-inline' *">
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
          <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
          <style>
            body { background: #d2d6de; }
            .logo-div { margin-top: 5%; }
            .loading-div { margin-top: 10%; }
            .text-center { text-align: center; }
            .loading-text { font-size: 16px; }
            .logo-image { max-width: 50%; }
            .loader { max-width: 40px; max-height: 40px; }
          </style>
        </head>
        <body>
          <div class="container">
            <div class="row logo-div">
              <div class="col-md-4"></div>
              <div class="col-md-4 text-center">
                <img src="${this.logoUrl || 'https://s3.ap-south-1.amazonaws.com/leegality-production-helpers/leegality-logo.png'}" class="logo-image">
              </div>
              <div class="col-md-4"></div>
            </div>
            <div class="row loading-div">
              <div class="col-md-4"></div>
              <div class="col-md-4 text-center">
                <p class="loading-text">Loading... Please Wait!</p>
                <img src="https://s3.ap-south-1.amazonaws.com/leegality-production-helpers/spinner2.gif" class="loader" />
              </div>
              <div class="col-md-4"></div>
            </div>
          </div>
        </body>
      </html>`;
    return loadingHtml;
  },

  init: function () {
    if (this.popup) this.popup.close();

    const width = 0.4 * window.screen.width;
    const height = 0.85 * window.screen.height;
    const left = 0.3 * window.screen.width;
    // const width = 1000;
    // const height = 500;
    // const left = 10;

    this.popup = window.open("", "_blank", `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=0,width=${width},height=${height},top=0, left=${left}`);
    this.popup.document.write(this.getLoadingHtml());

    const handleMessage = (event) => {
      const data = event.data;
      if (data && typeof data === "object" && (data.hasOwnProperty("error") || (data.hasOwnProperty("message") && data.hasOwnProperty("documentId")))) {
        this.responseData = data;
      }
    };

    window.addEventListener("message", handleMessage, false);

    const interval = window.setInterval(() => {
      if (this.responseData) {
        window.clearInterval(interval);
        this.handleResponse(this.responseData);
      } else if (this.popup.closed) {
        this.handleResponse({ error: "Cancelled." });
        window.clearInterval(interval);
      }
    }, 1000);
  },

  esign: function (url) {
    if (!url) {
    }
    if (!this.popup) {
      throw new LeegalityException("Initialize signing first.");
    }
    this.popup.location = url;
  },

  cancel: function () {
    if (this.popup) {
      this.popup.close();
    }
  }
};


export default Leegality
