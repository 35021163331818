import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Endpoints from "../config/apiConstants";
import callApi from "../helpers/networkHelper";
import PartyTwoDataFillForm from "../components/PartyTwoDataFillForm";
import { message } from "antd";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import Leegality from "../js/leegalityv6.js";

function PartyTwoDataFillPage() {
  const location = useLocation();
  const [token, setToken] = useState(undefined);
  const [verifyToke, setVerifyToke] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(undefined);
  const [success, setSuccess] = useState(false);
  const [signSuccess, setSignSuccess] = useState(false);
  const [redirected, setRedirected] = useState(false);

  const [selectedPdfData, setSelectedPdfData] = useState(null);
  const [viewPdfMd, setViewPdfMd] = useState(false);
  const [leegalityData, setLeegalityData] = useState(undefined);

  // let leegality
  // const obj = { logoUrl: "your-logo-url", callback: (res)=> { console.log(res) } }

  useEffect(() => {
    if (location.search) {
      console.log("location>>>", location.search.split("?")[1].split("=")[1]);
      setToken(location.search.split("?")[1].split("=")[1]);
      verifyTokenFun(location.search.split("?")[1].split("=")[1]);
    }
  }, []);

  async function verifyTokenFun(tokenData) {
    const method = Endpoints.VERIFY_PARTY_TWO_TOKEN.method;
    const url = Endpoints.VERIFY_PARTY_TWO_TOKEN.url;
    const body = {
      token: tokenData,
    };
    setLoading(true);

    try {
      const response = await callApi(method, url, body);
      console.log("verify token res>>", response);

      if (response.data.status) {
        setVerifyToke(true);
        setFormData(response.data.data);
      } else {
        setVerifyToke(false);
      }
    } catch (err) {
      console.error("token verify fun err", err);
    } finally {
      setLoading(false);
    }
  }

  async function fetchPreviewPdfData(fileName) {
    setLoading(true);
    try {
      const method = Endpoints.GET_PDF_URL_PARTY_TWO_PREVIEW.method;
      const url = Endpoints.GET_PDF_URL_PARTY_TWO_PREVIEW.url;
      const body = { fileName };

      const response = await callApi(method, url, body);
      // responseType: 'blob',
      console.log("Fetch pdf res>>", response);
      if (response.data.status) {
        setSelectedPdfData(response.data.url);
        setViewPdfMd(true);
      } else {
        message.error("PDF data fetching error!!");
      }
      // setPdfData(response.data);
    } catch (error) {
      console.error("Error fetching PDF:", error);
      message.error("PDF data fetching error!!");
    } finally {
      setLoading(false);
    }
  }

  function onEsignProcessClick() {
    console.log("redirect to leegality page>>");
    console.log("redirect to leegality page>>", leegalityData.invitees);
    console.log(
      "redirect to leegality page>>",
      leegalityData.invitees[1].signUrl
    );
    const obj = {
      logoUrl: "../assets/logo/logo-icon.svg",
      callback: (res) => sendSuccessSignRes(res),
    };
    const leegality = new Leegality(obj);
    leegality.init();
    leegality.esign(leegalityData.invitees[0].signUrl);
  }

  async function sendSuccessSignRes(data) {
    if (data.documentId) {
      const method = Endpoints.PARTY_TWO_ESIGN_DOC_UPDATE.method;
      const url = Endpoints.PARTY_TWO_ESIGN_DOC_UPDATE.url;
      const body = {
        userId: formData.userId,
        userName: formData.userName,
        formId: formData.formId,
        documentId: data.documentId,
      };
      try {
        const response = await callApi(method, url, body);
        console.log("party two esign doc update>>", response);

        if (response.data.status) {
          // message.success("Data updated successfully !!")
          console.log("party two esign doc updated successfully");
          setSignSuccess(true);
        } else {
          // message.error("Data updating fail !!")
          console.log("party two esign doc update fail");
        }
      } catch (err) {
        console.error("party two esign doc update err", err);
      } finally {
        setViewPdfMd(false);
        setSelectedPdfData(null);
      }
    }
  }

  return (
    <div className="flex flex-col justify-center items-center  lg:px-28 px-0 py-0">
      {loading && !verifyToke && (
        <h1 className="py-20 text-title-lg lg:text-title-xl text-center">
          Wait verifying token
        </h1>
      )}
      {!success && !loading && !verifyToke && !redirected && (
        <h1 className="py-20 text-title-lg lg:text-title-xl text-center">
          This page is no longer available !!!
        </h1>
      )}
      {!success && verifyToke && (
        <>
          {/* <h1 className='my-2'>Fill your data</h1> */}
          <div className="w-full px-4">
            <PartyTwoDataFillForm
              formData={formData}
              setSuccess={setSuccess}
              fetchPreviewPdf={fetchPreviewPdfData}
              setLeegalityData={setLeegalityData}
              setRedirected={setRedirected}
            />
          </div>
        </>
      )}
      {success && verifyToke && loading && (
        <h1 className="py-20 text-title-lg lg:text-title-xl text-center">
          Please wait while we are generating the document
        </h1>
      )}
      {success && verifyToke && !loading && viewPdfMd && selectedPdfData && (
        <>
          <h1 className="py-2 text-xl lg:text-title-xl text-center">
            Verify the PDF and proceed with the e-signature
          </h1>
          {/* view pdf */}
          <div className="w-full">
            <div className="h-[100vh] lg:h-[85vh] lg:overflow-scroll">
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}
              >
                <Viewer
                  fileUrl={selectedPdfData}
                  renderError={(e) => {
                    console.log("render error>>", e);
                  }}
                />
              </Worker>
            </div>
            <div className="flex justify-center items-center my-2">
              <button
                onClick={onEsignProcessClick}
                className="col-span-4 bg-blue-700 text-white font-bold py-2 px-4 shadow-4 rounded-lg disabled:bg-slate-400"
              >
                Click to proceed with the e-signature
              </button>
            </div>
          </div>
        </>
      )}
      {signSuccess && (
        <h1 className="py-20 text-title-lg lg:text-title-xl text-center">
          You have been successfully redirected to the e-sign link.
        </h1>
      )}
    </div>
  );
}

export default PartyTwoDataFillPage;
