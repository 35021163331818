// ProteanException function
function ProteanException(message) {
  this.name = "ProteanException";
  this.message = message;
}

// Protean function
function Protean(options) {
  if (!options || !options.callback) {
    throw new ProteanException("Callback function is required.");
  }

  const callback = options.callback;
  const logoUrl =
    options.logoUrl ||
    "https://s3.ap-south-1.amazonaws.com/Protean-production-helpers/Protean-logo.png";
  let popup = null;
  let responseData = null;

  const handleResponse = (response) => {
    cancel();
    callback(response);
    responseData = null;
  };

  const getLoadingHtml = () => `
      <html>
        <head>
          <meta http-equiv="Content-Security-Policy" content="default-src *; img-src * 'self' data: https:; script-src 'self' 'unsafe-inline' 'unsafe-eval' *; style-src 'self' 'unsafe-inline' *">
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
          <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
          <style>
            body { background: #d2d6de; }
            .logo-div { margin-top: 5%; }
            .loading-div { margin-top: 10%; }
            .text-center { text-align: center; }
            .loading-text { font-size: 16px; }
            .logo-image { max-width: 50%; }
            .loader { max-width: 40px; max-height: 40px; }
          </style>
        </head>
        <body>
          <div class="container">
            <div class="row logo-div">
              <div class="col-md-4"></div>
              <div class="col-md-4 text-center">
                <img src="${logoUrl}" class="logo-image">
              </div>
              <div class="col-md-4"></div>
            </div>
            <div class="row loading-div">
              <div class="col-md-4"></div>
              <div class="col-md-4 text-center">
                <p class="loading-text">Loading... Please Wait!</p>
                <img src="https://s3.ap-south-1.amazonaws.com/Protean-production-helpers/spinner2.gif" class="loader" />
              </div>
              <div class="col-md-4"></div>
            </div>
          </div>
        </body>
      </html>`;

  const init = () => {
    if (popup) popup.close();

    const width = 0.4 * window.screen.width;
    const height = 0.85 * window.screen.height;
    const left = 0.3 * window.screen.width;

    popup = window.open(
      "",
      "_blank",
      `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=0,width=${width},height=${height},top=0,left=${left}`
    );
    popup.document.write(getLoadingHtml());

    const handleMessage = (event) => {
      const data = event.data;
      if (
        data &&
        typeof data === "object" &&
        (data.hasOwnProperty("error") ||
          (data.hasOwnProperty("message") && data.hasOwnProperty("documentId")))
      ) {
        responseData = data;
      }
    };

    window.addEventListener("message", handleMessage, false);

    const interval = window.setInterval(() => {
      if (responseData) {
        window.clearInterval(interval);
        handleResponse(responseData);
      } else if (popup.closed) {
        handleResponse({ error: "Cancelled." });
        window.clearInterval(interval);
      }
    }, 1000);
  };

  const esign = (url) => {
    if (!url) {
      throw new ProteanException("URL is required for esign.");
    }
    if (!popup) {
      throw new ProteanException("Initialize signing first.");
    }
    popup.location = url;
  };

  const cancel = () => {
    if (popup) {
      popup.close();
      popup = null;
    }
  };

  return {
    init,
    esign,
    cancel,
  };
}

export default Protean;
