import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Row, Spin } from "antd";

export default function Loader(props) {
  const {
    size = "20px",
    padding = "10px",
    height = "200px",
    message = "Loading",
  } = props;
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: size,
      }}
      spin
    />
  );
  return (
    <Row align="middle" justify="center" style={{ padding, height }}>
      <Spin indicator={antIcon} />
      {message && <div style={{ paddingLeft: 6 }}>{message}</div>}
    </Row>
  );
}
